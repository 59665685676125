import { createSlice } from "@reduxjs/toolkit";
import { getCurrentMenuIndex, setCurrentMenuIndex } from "../../utils/storage";

const initialState = {
  menuSelected: getCurrentMenuIndex() ?? 0
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    changeMenu(state, action) {
      setCurrentMenuIndex(action.payload);
      console.log({ state, action });
      state.menuSelected = action.payload;
    },
  },
});

export const { changeMenu } = drawerSlice.actions;
export default drawerSlice;
