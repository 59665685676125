import React from "react";
import Avatar from "@mui/material/Avatar";

// import { defaultUser } from "../../data/AuthUser";
const defaultUser = {
  firstname: "",
  lastname: "",
  avatarUrl: ""
}

type AvatarProps = {
  firstname: string;
  lastname: string;
  avatarUrl: string;
  size?: string | number;
  fontSize?: string | number;
  disableOutline?: string | boolean;
  bgColor?: string;
  textColor?: string;
};

function UserAvatar(props: AvatarProps) {
  const sizeOptions = props.size
    ? { width: props.size, height: props.size }
    : {};

  const data = {
    ...defaultUser,
    firstname: props.firstname ?? "",
    lastname: props.lastname ?? "",
    avatarUrl: props.avatarUrl ?? "",
  };

  return (
    <div
      style={{
        display: "inline-block",
        borderRadius: "50%",
        border: (props.disableOutline) ? "none" : "1px solid #ccc",
        padding: (props.disableOutline) ? "0" : "5px",
      }}
    >
      <Avatar
        sx={{
          bgcolor: props.bgColor ?? "#FFECE5",
          color: props.textColor ?? "#ffffff",
          fontWeight: "800",
          fontSize: props.fontSize,
          ...sizeOptions,
        }}
        alt={`${data.firstname} ${data.lastname}`}
        src={data.avatarUrl}
      >
        {`${data.firstname.charAt(0)?.toUpperCase()}${data.lastname
          .charAt(0)
          ?.toUpperCase()}`}
      </Avatar>
    </div>
  );
}

export default UserAvatar;
