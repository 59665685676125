import React, { useState, Fragment } from "react";

import {
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const CustomListItem = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = (propOnClick) => {
    return (ev) => {
      if (propOnClick) {
        propOnClick(ev);
      }
      // console.log(ev);
      setOpen(!open);
    };
  };

  const moreIcon = open ? <ExpandLess /> : <ExpandMore />;

  return (
    <Fragment>
      <ListItemButton
        selected={!!props.selected}
        onClick={handleClick(props.onClick)}
        href={props.href ?? null}
        disabled={!!props.disabled}
        className="rounded-md"
        sx={{
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: "white",

            "*": {
              color: "#9093aa",
            },
          },
        }}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText className="list-item-text" primary={props.text ?? ""} />
        {!props.children ? null : moreIcon}
      </ListItemButton>
      {!props.children ? null : (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {props.children}
        </Collapse>
      )}
    </Fragment>
  );
};

export default CustomListItem;
