import rules, { Rules } from "./rules";

type CheckProps = {
  rules: Rules;
  role: string;
  action: string;
  data?: any;
}

export const check = (props: CheckProps): Boolean => {
  const { role, action, data } = props;
  // console.log(rules);
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions: { [key: string]: Function } | undefined = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

type CanProps = {
  role: string;
  perform: string;
  data?: any;
  yes: () => JSX.Element | null;
  no: () => JSX.Element | null;
}

const Can = (props: CanProps) => {
  return check({
    rules,
    role: props.role,
    action: props.perform,
    data: props.data,
  })
    ? props.yes()
    : props.no();
};

export default Can;
