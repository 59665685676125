import React, { useState } from "react";
import { Outlet } from "react-router-dom";
// import { TourProvider } from '@reactour/tour'

import { Stack } from "@mui/material";

import Header from "../components/Header";
import Drawer from "../components/Drawer";

function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const steps = [
  //   {
  //     selector: '.first-step',
  //     content: 'This is my first Step',
  //   }
  // ];

  return (
    // <TourProvider steps={steps}>
    <Stack direction="row">
      <Drawer isOpen={mobileOpen} toggler={handleDrawerToggle} />
      <Stack direction="column" sx={{ width: "100%" }}>
        <Header toggler={handleDrawerToggle} />

        <main id="main-page-content">
          <Outlet />
        </main>
      </Stack>
    </Stack>
    // </TourProvider>
  );
}

export default Dashboard;
