export type Rules = {
  [key: string]: {
    static: String[],
    dynamic?: {
      [key: string]: Function,
    },
  }
}

const rules: Rules = {
  superadmin: {
    static: [
      "dashboard:visit",
      "dashboard:admin:view",
      "dashboard-stats:view:admin",
      "dashboard-filters:view",
      "loans:visit",
      "loan-stats:summary:view",
      "loan-stats:history:view",
      "loans:admin:view",
      "loans:approve",
      "loans:decline",
      "vendors:visit",
      "vendors:admin:view",
      "vendor-stats:view-summary",
      "organization:visit",
      "personnel:visit",
      "company:visit",
    ],
    dynamic: {},
  },
  admin: {
    static: [
      "dashboard:admin:visit",
      "loans:admin:visit",
      "loans:approve",
      "loans:decline",
      "agribusiness:admin:visit",
      "user-management:visit",
      "settings:visit",

      "dashboard:admin:view",
      "dashboard-stats:view:admin",
      "dashboard-filters:view",
      "loan-stats:summary:view",
      "loan-stats:history:view",
      "loans:admin:view",
    ],
    dynamic: {},
  },
  client: {
    static: [
      "dashboard:visit",
      "loans:visit",
      "loans:approve",
      "loans:decline",
      "company:visit",
      "dashboard-stats:view:admin",
      "dashboard-filters:view",
      "loan-stats:summary:view",
      "loan-stats:history:view",
      // "agribusiness:visit",
      "profile-settings:visit",
    ],
    dynamic: {},
  },
  bank: {
    static: [
      "dashboard:bank:visit",
      "loans:bank:visit",
      "loans:approve",
      "loans:decline",
      // "agribusiness:admin:visit",
      // "user-management:visit",
      "settings:visit",
    ],
    dynamic: {},
  },
  staff: {
    static: [
      "dashboard:visit",
      "dashboard:client:view",
      "dashboard-stats:view:user",
      "loans:export:visit",
      "loan-stats:history:view",
      "loans:client:view",
      // "account:visit",
      "vendors:visit",
      "vendors:client:view",
      "company:visit",
    ],
    dynamic: {},
  },
};

export default rules;
