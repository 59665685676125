import React from "react";

import {
  Route,
  Outlet,
  Routes as Switch,
  BrowserRouter as Router,
  // Redirect
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";

// Import Utility Components
import NoPage from "./components/UnknownPage";
import ErrorBoundary from "./components/ErrorBoundary";
import UnauthorizedPage from "./components/Unauthorized";
import SuspenseFallback from "./components/SuspenseFallback";

// Import Layouts
import Auth from "./layouts/Auth";
import ViewArea from "./layouts/ViewArea";
import Dashboard from "./layouts/Dashboard";

import { useSelector } from "react-redux";
import { StoreState } from "./interfaces";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages Components for Layout
const DashboardIndexSection: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/dashboard/AgroBusinessDashboard"));

const AdminDashboardIndexSection: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/dashboard/AdminDashboard"));

const BankingPartnerDashboardIndexSection: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/dashboard/BankingPartnerDashboard"));

const ChooseAuth: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/ChooseAuth")
);

const Login: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/Login")
);

const AdminLogin: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/AdminLogin")
);

const BankLogin: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/BankLogin")
);

const Register: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/Register")
);

const ForgotPassword: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/auth/ForgotPassword"));

const ResetPassword: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/auth/ResetPassword"));

const SetupPin: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/SetupPin")
);

const VerifyOTP: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auth/VerifyOTP")
);

const VerifyExistingProfile: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/auth/VerifyExistingProfile"));

const CompleteMyProfile: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/userprofile/CompleteMyProfile"));

const LoanApplication: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/loans/agribusiness/LoanApplication"));

const LoanIndex: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/loans/agribusiness/Index")
);

const ViewLoan: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/loans/agribusiness/ViewLoan")
);

const Agribusinessess: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/agribusinesses/Index"));

const ViewAgribusiness: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/agribusinesses/ViewBusiness"));

const UserManagement: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/userManagement/Index"));

const ViewUser: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/userManagement/ViewUser")
);

const AdminLoanIndex: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/loans/admin/Index"));

const BankingPartnerLoanIndex: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/loans/banking-partner/Index"));

const AdminViewLoanRequest: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/loans/admin/ViewLoanRequest"));

const BPViewLoanRequest: React.LazyExoticComponent<React.ComponentType> =
  React.lazy(() => import("./pages/loans/banking-partner/ViewLoanRequest"));

const Settings: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/settings/Index")
);

const CompanyIndex: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/company/Index")
);

const AuditLogs: React.LazyExoticComponent<React.ComponentType> = React.lazy(
  () => import("./pages/auditLogs/Index")
);

// create a default container so we can override the styles
const ToastContainer = (props = {}) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

const ProtectedRoute = () => {
  const { userProfile } = useSelector((state: StoreState) => state.Auth);

  if (!userProfile) {
    return <UnauthorizedPage />;
  }

  return <Outlet />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Router>
            <Switch>
              <Route path="/" element={<Auth />}>
                <Route index element={<ChooseAuth />} />
                <Route path="login" element={<Login />} />
                <Route path="admin-login" element={<AdminLogin />} />
                <Route path="bank-login" element={<BankLogin />} />

                <Route path="register" element={<Register />} />
                <Route path="auth/setup-pin" element={<SetupPin />} />
                <Route path="verify/otp" element={<VerifyOTP />} />
                <Route
                  path="verify/existing-profile"
                  element={<VerifyExistingProfile />}
                />
                <Route
                  path="auth/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route
                  path="reset-password/:token"
                  element={<ResetPassword />}
                />
              </Route>

              {/* DASHBOARD */}
              <Route path="/dashboard" element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path="admin" element={<AdminDashboardIndexSection />} />
                <Route
                  path="banking-partner"
                  element={<BankingPartnerDashboardIndexSection />}
                />
              </Route>

              {/* PROFILE */}
              <Route path="/profile" element={<ViewArea />}>
                <Route path="update" element={<CompleteMyProfile />} />
              </Route>

              {/* LOAN APPLICATION */}
              <Route path="/loan-management" element={<ViewArea />}>
                <Route path="application" element={<LoanApplication />} />
              </Route>

              {/* LOAN MANAGEMENT */}
              <Route path="/loan-management" element={<Dashboard />}>
                <Route index element={<LoanIndex />} />
                <Route path="view/:loanId" element={<ViewLoan />} />
                <Route
                  path="banking-partner"
                  element={<BankingPartnerLoanIndex />}
                />
                <Route
                  path="banking-partner/view/:loanId"
                  element={<BPViewLoanRequest />}
                />
                <Route path="admin" element={<AdminLoanIndex />} />
                <Route
                  path="admin/view/:loanId"
                  element={<AdminViewLoanRequest />}
                />
                {/* <Route path="export-documents/:loanId" element={<Exports />} /> */}
              </Route>

              {/* AGRIBUSINESSES */}
              <Route path="/agribusiness" element={<Dashboard />}>
                <Route index element={<Agribusinessess />} />
                <Route path="view/:businessId" element={<ViewAgribusiness />} />
              </Route>

              {/* USER MANAGEMENT */}
              <Route path="/user-management" element={<Dashboard />}>
                <Route index element={<UserManagement />} />
                <Route path="view/:userId" element={<ViewUser />} />
              </Route>

              {/* SETTINGS */}
              <Route path="/settings" element={<Dashboard />}>
                <Route index element={<Settings />} />
              </Route>

              {/* COMPANY */}
              <Route path="/company" element={<Dashboard />}>
                <Route index element={<CompanyIndex />} />
              </Route>

              {/* AUDIT LOGS */}
              <Route path="/audits" element={<Dashboard />}>
                <Route index element={<AuditLogs />} />
              </Route>

              <Route path="*" element={<NoPage />} />
            </Switch>
          </Router>
          <ToastContainer />
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
