// import LoadingSpinner from "./common/LoadingSpinner";
import Lottie from "./common/Lottie";

import loadingLottie from "../assets/lotties/CreditLoadAnimation.json";

const SuspenseFallback = (): JSX.Element => {
  return (
    <div className="flex h-screen w-screen justify-center items-center">
      <Lottie data={loadingLottie} width={150} height={150}/>
      {/* <LoadingSpinner color="border-green-700" /> */}
    </div>
  );
};

export default SuspenseFallback;
