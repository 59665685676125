import { Outlet } from "react-router-dom";

import { Stack } from "@mui/material";

import Header from "../components/Header";

function ViewArea() {
  return (
    <Stack direction="column">
      <Header disableMenu="true"/>
      <main id="view-page-content">
        <Outlet />
      </main>
    </Stack>
  );
}

export default ViewArea;
