import React from "react";
import { Outlet } from "react-router-dom";

import "./Auth.css"; 

const Auth = (): JSX.Element => {
  return (
    <main id="auth-screen" className="flex flex-col">
      <div className="md:w-4/5 w-full h-full mx-auto flex flex-col justify-center items-center p-10">
        <Outlet />
      </div>
    </main>
  );
};

export default Auth;
