import { IconContext } from "react-icons";
// import { useNavigate } from "react-router-dom";
import React, { MouseEventHandler } from "react";

import {
  Menu,
  Link,
  Stack,
  AppBar,
  Toolbar,
  MenuItem,
  IconButton,
  CssBaseline,
  Button as MuiButton,
} from "@mui/material";

import { CgMenuLeftAlt as MenuIcon } from "react-icons/cg";
// import { IoIosSettings as SettingIcon } from "react-icons/io";
// import { RiQuestionFill as QuestionIcon } from "react-icons/ri";

// import { defaultUser } from "../data/AuthUser";
import UserAvatar from "./common/UserAvatar";

import { theme } from "../context/ThemeProvider";
import { logout } from "../redux/services/auth/auth.service";

import { getAuthUser, getRole } from "../utils/storage";

type HeaderProps = {
  toggler?: () => void;
  disableMenu?: "true" | "false" | boolean;
};

function Header(props: HeaderProps) {
  // const dispatch = useDispatch<any>();

  const authenticatedUser = getAuthUser();
  const role = getRole();
  const userRole: { [x: string]: string } = {
    client: "agribusiness",
    admin: "Admin",
    bank: "banking partner",
  };

  console.log({ role });

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const open = Boolean(anchorEl);
  const toggleProfileMenu =
    (show: boolean): MouseEventHandler =>
    (event: any) => {
      if (show) {
        setAnchorEl(event.currentTarget as Element);
      } else {
        setAnchorEl(null);
      }
    };

  const handleLogout: MouseEventHandler = (event) => {
    // dispatch();
    logout();
    toggleProfileMenu(false)(event);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="relative"
        elevation={0}
        color="secondary"
        sx={{
          boxShadow: "0px 3px 10px rgba(108, 117, 125, 0.15)",
          mb: 0.2,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <div>
            <IconContext.Provider value={{ size: "28px", color: "#393A4A" }}>
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                // onClick={props.toggler}
                sx={{ margin: "0 5px" }}
              >
                <QuestionIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                // onClick={props.toggler}
                sx={{ margin: "0 5px" }}
              >
                <SettingIcon />
              </IconButton> */}
            </IconContext.Provider>

            <MuiButton
              id="header-profile-button"
              sx={{ padding: "10px 10px 10px 20px" }}
              aria-controls={open ? "header-profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={toggleProfileMenu(true)}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  overflow: "hidden",
                }}
              >
                <UserAvatar
                  size="32px"
                  fontSize="13px"
                  disableOutline="true"
                  avatarUrl={authenticatedUser?.tenant?.avatar_url ?? ""}
                  firstname={authenticatedUser?.user?.first_name ?? ""}
                  lastname={authenticatedUser?.user?.last_name ?? ""}
                  textColor="#101928"
                />
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{
                    ml: "13px",
                    textTransform: "capitalize",
                    color: "#393A4A",
                  }}
                >
                  <h3
                    style={{ margin: "0", fontWeight: "800", fontSize: "14px" }}
                  >
                    {`${authenticatedUser?.user?.first_name ?? "-"} ${
                      authenticatedUser?.user?.last_name ?? "-"
                    }`}
                  </h3>
                  <span style={{ fontSize: "12px" }}>
                    {authenticatedUser?.user?.admin_role?.name ??
                      userRole[role] ??
                      "--"}
                  </span>
                </Stack>
              </Stack>
            </MuiButton>
            <Menu
              id="header-profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={toggleProfileMenu(false)}
              MenuListProps={{
                "aria-labelledby": "header-profile-button",
              }}
              sx={{
                "& .MuiMenu-paper": {
                  borderRadius: "15px",
                  boxShadow: "0px 5px 20px rgba(108, 117, 125, 0.4)",
                },
              }}
            >
              {role === "client" && (
                <MenuItem onClick={toggleProfileMenu(false)}>
                  <MuiButton
                    href="/profile/update"
                    sx={{
                      p: 0,
                      m: 0,
                      textTransform: "capitalize",
                      color: "inherit",
                      fontSize: "inherit",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Update Profile
                  </MuiButton>
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>

          <div className="flex flex-row items-center">
            <IconContext.Provider
              value={{ size: "28px", color: `${theme.palette.primary.main}` }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={props.toggler}
                sx={{
                  ml: -1,
                  mr: 1.5,
                  display: props.disableMenu ? "none" : { lg: "none" },
                }}
              >
                <MenuIcon />
              </IconButton>
            </IconContext.Provider>
            <Link
              href="/dashboard"
              // sx={{ textDecoration: "none" }}
              color={"#393A4A"}
              fontWeight={800}
              underline={"none"}
            >
              Dashboard
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
