import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";

import {
  Box,
  Stack,
  List,
  IconButton,
  ListSubheader,
  Drawer as MuiDrawer,
} from "@mui/material";

import { IoSpeedometerOutline as DashboardIcon } from "react-icons/io5";
import { AiOutlineLogout as LogoutIcon } from "react-icons/ai";
// import { TbFileExport as ExportIcon } from "react-icons/tb";
import { GiSquare as SquareIcon } from "react-icons/gi";

import {
  // RiUserLine as UserIcon,
  RiBuilding2Line as OrgIcon,
} from "react-icons/ri";

import { MdPeopleOutline as UserManagementIcon } from "react-icons/md";

import {
  BsCashCoin,
  BsGear as SettingIcon,
  BsFillPersonLinesFill as PersonIcon,
} from "react-icons/bs";

import {
  CgClose as CloseIcon,
  CgMenuLeftAlt as MenuIcon,
} from "react-icons/cg";

import Can from "../utils/rbac/Can";
import { theme } from "../context/ThemeProvider";
import CustomListItem from "./common/CustomListItem";

import logo from "../assets/logo/project_grow_logo_full_color_1.svg";
// import { StoreState } from "../redux/store";
import { logout } from "../redux/services/auth/auth.service";
import { changeMenu } from "../redux/slices/Drawer.slice";
import { getAuthUser, getRole } from "../utils/storage";
import { StoreState } from "../interfaces";

type DrawerContentProps = {
  closeHandler?: () => void;
};

const DrawerContent = ({ closeHandler }: DrawerContentProps) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [authenticatedUser, setAuthenticatedUser] = useState(defaultUser);
  const role = getRole();
  const authenticatedUser = { role, passwordUpdated: false } ?? getAuthUser();

  // const [listSelected, setListSelected] = useState(
  //   parseInt(`${localStorage.getItem("menu_selected") || 0}`, 10)
  // );
  const listSelected = useSelector((state: StoreState) =>
    parseInt(state?.Drawer?.menuSelected, 10)
  );

  const handleListItemClick = (index: number) => () => {
    dispatch(changeMenu(index));
  };

  const handleLogout =
    (index: number) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      handleListItemClick(index);
      logout();
    };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 360,
        bgcolor: "var(--secondary-main-color)",
        color: "#fff",
        px: 2,
      }}
    >
      <List
        sx={{
          width: "100%",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{
              padding: "0.8rem 0",
              backgroundColor: "transparent",
            }}
          >
            <Stack
              direction="row-reverse"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                display: {
                  lg: "none",
                },
              }}
            >
              <IconContext.Provider
                value={{ size: "28px", color: `${theme.palette.primary.main}` }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {}}
                  edge="start"
                  sx={{
                    display: {
                      xs: "none",
                      // sm: "none",
                      lg: "block",
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={closeHandler}
                  edge="start"
                  sx={{
                    display: {
                      xs: "block",
                      // sm: "block",
                      lg: "none",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </IconContext.Provider>
            </Stack>

            <div className="flex items-center justify-center flex-grow py-4">
              <img src={logo} alt="tradebuza logo" id="drawer-logo" />
            </div>
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              <Can
                role={authenticatedUser?.role}
                perform="dashboard:visit"
                yes={() => (
                  <CustomListItem
                    icon={<DashboardIcon />}
                    text="Dashboard"
                    href="/dashboard"
                    selected={listSelected === 0}
                    onClick={handleListItemClick(0)}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="dashboard:admin:visit"
                yes={() => (
                  <CustomListItem
                    icon={<DashboardIcon />}
                    text="Dashboard"
                    href="/dashboard/admin"
                    selected={listSelected === 0}
                    onClick={handleListItemClick(0)}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="dashboard:bank:visit"
                yes={() => (
                  <CustomListItem
                    icon={<DashboardIcon />}
                    text="Dashboard"
                    href="/dashboard/banking-partner"
                    selected={listSelected === 0}
                    onClick={handleListItemClick(0)}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="loans:visit"
                yes={() => (
                  <CustomListItem
                    icon={<BsCashCoin />}
                    text="Loan Management"
                    href="/loan-management"
                    selected={listSelected === 1}
                    onClick={handleListItemClick(1)}
                    // disabled={!authenticatedUser?.passwordUpdated}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="loans:admin:visit"
                yes={() => (
                  <CustomListItem
                    icon={<BsCashCoin />}
                    text="Loan Management"
                    href="/loan-management/admin"
                    selected={listSelected === 1}
                    onClick={handleListItemClick(1)}
                    // disabled={!authenticatedUser?.passwordUpdated}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="loans:bank:visit"
                yes={() => (
                  <CustomListItem
                    icon={<BsCashCoin />}
                    text="Loan Management"
                    href="/loan-management/banking-partner"
                    selected={listSelected === 1}
                    onClick={handleListItemClick(1)}
                    // disabled={!authenticatedUser?.passwordUpdated}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="company:visit"
                yes={() => (
                  <CustomListItem
                    icon={<OrgIcon />}
                    text="Company"
                    href="/company"
                    selected={listSelected === 2}
                    onClick={handleListItemClick(8)}
                    // disabled={!authenticatedUser?.passwordUpdated}
                  />
                )}
                no={() => null}
                data={null}
              />
              <Can
                role={authenticatedUser?.role}
                perform="agribusiness:admin:visit"
                no={() => null}
                yes={() => (
                  <CustomListItem
                    icon={<OrgIcon />}
                    text="Agribusinesses"
                    href="/agribusiness"
                    selected={listSelected === 6}
                    onClick={handleListItemClick(6)}
                    // disabled={true}
                  />
                )}
              />
              <Can
                role={authenticatedUser?.role}
                perform="user-management:visit"
                no={() => null}
                yes={() => (
                  <CustomListItem
                    icon={<UserManagementIcon />}
                    text="User Management"
                    href="/user-management"
                    selected={listSelected === 10}
                    onClick={handleListItemClick(10)}
                    disabled={true}
                  />
                )}
              />

              <Can
                role={authenticatedUser?.role}
                perform="agribusiness:admin:visit"
                no={() => null}
                yes={() => (
                  <CustomListItem
                    icon={<SquareIcon />}
                    text="Audit Logs"
                    href="/audits"
                    selected={listSelected === 11}
                    disabled={true}
                    onClick={handleListItemClick(11)}
                  />
                )}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 2, 3, 4].map((item, index) => (
                <div key={index} className="flex flex-row mb-6 px-5">
                  <div
                    className="lazy-loading p-3 mr-4"
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className="lazy-loading p-3"
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>

      <List
        sx={{
          width: "100%",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="h2"
            id="nested-list-subheader"
            sx={{
              padding: "0.1rem 15px",
              backgroundColor: "transparent",
              color: "#fff",
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            YOUR ACCOUNT
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              <Can
                role={authenticatedUser?.role}
                perform="profile-settings:visit"
                yes={() => (
                  <CustomListItem
                    icon={<PersonIcon />}
                    text="Settings"
                    href="/settings"
                    selected={listSelected === 7}
                    onClick={handleListItemClick(7)}
                    disabled={!authenticatedUser?.passwordUpdated}
                  />
                )}
                no={() => null}
              />

              <Can
                role={authenticatedUser?.role}
                perform="settings:visit"
                yes={() => (
                  <CustomListItem
                    icon={<SettingIcon />}
                    text="Settings"
                    href="/settings"
                    selected={listSelected === 8}
                    onClick={handleListItemClick(8)}
                    disabled={!authenticatedUser?.passwordUpdated}
                  />
                )}
                no={() => null}
              />

              <CustomListItem
                icon={<LogoutIcon />}
                text="Logout"
                selected={listSelected === 9}
                onClick={handleLogout(9)}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 2].map((item, index) => (
                <div key={index} className="flex flex-row mb-6 px-5">
                  <div
                    className="lazy-loading p-3 mr-4"
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className="lazy-loading p-3"
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>
    </Stack>
  );
};

type DrawerProps = {
  isOpen?: boolean;
  toggler?: () => void;
};

function Drawer({ isOpen, toggler }: DrawerProps): JSX.Element {
  const drawerWidth = 270;
  const styles = {
    drawer_paper: {
      width: drawerWidth,
      backgroundColor: "var(--secondary-main-color)",
      boxSizing: "border-box",
    },
  };

  return (
    <Box
      component="nav"
      id="main-drawer"
      sx={{
        width: { lg: drawerWidth },
        flexShrink: { lg: 0 },
      }}
      aria-label="Navigation Options"
    >
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={toggler}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            ...styles.drawer_paper,
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
      <MuiDrawer
        variant="permanent"
        open
        sx={{
          display: { xs: "none", lg: "block" },

          // display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            ...styles.drawer_paper,
            position: "relative",
            height: "calc(100vh)",
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
    </Box>
  );
}

export default Drawer;
