import { GenericObject } from "../interfaces";
import { getFileType } from "./utility";

export const saveToStorage = (
  payload: GenericObject,
  storageType: string = "session"
) => {
  Object.keys(payload).forEach((key) => {
    switch (storageType) {
      case "local":
        localStorage.setItem(key, payload[key]);
        break;

      case "session":
      default:
        sessionStorage.setItem(key, payload[key]);
        break;
    }
  });
};

export const fetchFromStorage = (
  key: string,
  storageType: string = "session"
) => {
  switch (storageType) {
    case "local":
      return localStorage.getItem(key);

    case "session":
    default:
      return sessionStorage.getItem(key);
  }
};

export const clearStorage = (key?: string) => {
  if (key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  } else {
    localStorage.clear();
    sessionStorage.clear();
  }
};

export const getAuthUser = () =>
  JSON.parse(localStorage.getItem("user_profile") || "{}");

export const setAuthUser = (profile: { [x: string]: any }) =>
  saveToStorage({ user_profile: JSON.stringify(profile) }, "local");

export const getToken = () => localStorage.getItem("access_token");

export const setToken = (token: string) =>
  saveToStorage({ access_token: token }, "local");

export const getRole = () => localStorage.getItem("user") ?? "";

export const setRole = (token: string) =>
  saveToStorage({ user: token }, "local");

export const setCurrentMenuIndex = (index: string | number) =>
  saveToStorage({ menu_selected: `${index}` }, "session");

export const getCurrentMenuIndex = () =>
  sessionStorage.getItem("menu_selected");

export const getSavedProfileForm = () =>
  JSON.parse(sessionStorage.getItem("saved_profile_form") || "{}");

export const saveProfileForm = (payload: GenericObject) => {
  const formatPayload = (payload: GenericObject) => {
    const retValue: GenericObject = {};

    const listedObject = Object.entries(payload);

    listedObject.forEach(([key, value]) => {
      switch (true) {
        case ["string", "number", "bigint", "boolean"].includes(typeof value):
          // console.log(`${key} is a normal data type`);
          retValue[key] = value;
          break;

        case Array.isArray(value) && typeof value?.[0] === "object":
          // console.log(`${key} is an array of objects`);
          retValue[key] = value.map((each: any) =>
            formatPayload(each as GenericObject)
          );
          break;

        case value instanceof File:
          // console.log(`${key} is instance of File`);
          // console.log(key, value);
          // retValue[key] = null;
          break;

        case !!value && typeof value === "object":
          // console.log(`${key} is an object`);
          retValue[key] = formatPayload(value as GenericObject);
          break;

        default:
          retValue[key] = null;
          break;
      }
    });

    return retValue;
  };

  saveToStorage(
    { saved_profile_form: JSON.stringify(formatPayload(payload)) },
    "session"
  );
};

export const getAttachmentsFromProfile = (
  businessProfile: GenericObject | null
): { name: string; url: string; type: string }[] | GenericObject[] => {
  return !businessProfile
    ? ([] as GenericObject[])
    : ([] as GenericObject[]).concat(
        Object.entries(businessProfile)
          .concat(
            (businessProfile?.identification_documents || []).map(
              (each: any) => {
                let key = "means of identification";
                return [each?.type || key, each?.link || each?.document];
              }
            )
          )
          .filter(([key, value]) => {
            const documentFields: string[] = [
              "cac",
              "file",
              "search_report",
              "utility_bill",
              "account_opening_form",
              "passport",
              "memat",
              "form_co7",
              "form_co2",
              "board_resolution",
              "international passport",
              "national id",
              "drivers license",
              "means of identification",
            ];

            const isDocumentField =
              documentFields.includes(key.toLowerCase()) &&
              typeof value === "string" &&
              value !== "null" &&
              !!value;

            return isDocumentField;
          })
          .map(([key, value]) => {
            const url = (value as string).replaceAll(/,+/g, ".");
            return {
              name: key,
              url,
              type: getFileType(url),
            };
          })
      );
};
