import { Theme } from '@mui/material';
import { createTheme, ThemeProvider as TP } from '@mui/material/styles';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      light: '#EBFFF7',
      main: '#00A15F',
      dark: '#023D25'
    },
    secondary: {
      main: '#fff',
      dark: '#F4F4F4',
    },
  },
});

export const ThemeProvider = TP;